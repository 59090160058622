<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">
      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <h3>RECIBO</h3>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-3">
          <label class="ml-auto vs-input--label">O CFC</label>
          <v-select @input="changeTypeSingleReceipt" class="ml-auto" v-model="singleReceipt.type_single_receipt" :reduce="option => option.value" :clearable="false"
            :options="[
                {value: 'pay', label: 'Paga'},
                {value: 'receive', label: 'Recebe'},
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div> 
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <div>
            <label class="text-sm">Tipo de Recibo</label>
            <div class="mt-2">
              <vs-radio v-model="singleReceipt.type_person" vs-value="student" class="mr-4" v-on:change="changePlaceHolder()">Aluno</vs-radio>
              <vs-radio v-model="singleReceipt.type_person" vs-value="provider" class="mr-4" v-on:change="changePlaceHolder()">Fornecedor</vs-radio>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-input class="w-full" label="" @input="changeCurrency" ref="singleReceiptValue" v-model="singleReceipt.value" v-currency="currencyConfig" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <!-- <select-student-or-input-text label="Recebi de:" v-model="student" @input="afterSelectStudent"/> -->
          <div>
            <div class="vs-input--label">
              <sig-button-info v-bind:title="`Nome ou ${type_person_label}`" v-bind:text="`Permite digitar o nome avulso ou selecionar o aluno. Clique na opção desejada - Nome -  ${type_person_label}`">
                <div class="inline-flex">
                  {{ label }}
                  <span v-if="label">
                    &nbsp;
                  </span>
                  <span :class="classAccount" @click="changeSelect" >Digitar nome</span>
                  &nbsp;-&nbsp;
                  <span :class="classCashier" @click="changeSelect" >Selecionar {{ type_person_label  }}</span>
                </div>
              </sig-button-info>
            </div>
            <select-student v-model="student" label="" @input="changeValue" v-if="showSelectStudent" v-bind:type_person="singleReceipt.type_person"/>
            <vs-input class="w-full" v-model="nameStudent" @input="changeValue" v-else />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full" label="CPF/CNPJ" v-model="singleReceipt.payer_cpf_cnpj" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-as="CPF" data-vv-scope="sigleReceiptForm" v-validate="'cpf'" name="cpf" />
          <span class="text-danger text-sm" v-show="errors.has('sigleReceiptForm.cpf')">O documento não é válido</span>
        </div>
      </div>
      <div class="flex items-end mt-2">
        <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Endereço</span>
      </div>
      <hr>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="singleReceipt.payer_zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
        </div>

        <div class="vx-col md:w-1/2 w-full mt-2">
          <vs-input class="w-full" label="Endereço" v-model="singleReceipt.payer_street" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="Número" v-model="singleReceipt.payer_number" type="number" placeholder="S/N" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="singleReceipt.payer_complement" />
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="singleReceipt.payer_neighborhood" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Cidade</label>
          <v-select class="vue_select_drop_size_240" :reduce="option => option.value" v-model="singleReceipt.payer_city_id"
            :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select class="vue_select_drop_size_240" :reduce="option => option.value"
            v-model="singleReceipt.payer_state_id"
            @input="fillCity(singleReceipt.payer_state_id, null), singleReceipt.payer_city_id = null"
            :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-4 mb-4">
            <strong>A importância de {{ singleReceipt.valueExtenso }}</strong>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0 mb-0">
          <vs-input type="date" class="w-full" label="Pago em" v-model="singleReceipt.data" data-vv-scope="sigleReceiptForm" data-vv-as="Pago em" v-validate.initial="'required'" name="data" />
          <span class="text-danger text-sm" v-show="errors.has('sigleReceiptForm.data')">{{ errors.first('sigleReceiptForm.data') }}</span>
        </div>
      </div>

      <hr class="mt-2" />

      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-4 mb-4">
          <label class="vs-input--label">Referente:</label>
          <vs-textarea v-model="singleReceipt.referente" rows="5" width="100%" placeholder="..."></vs-textarea>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-4 mb-4">
          <label class="vs-input--label">Tipos de pagamento da conta</label>
          <v-select v-model="singleReceipt.type_payment_id" :reduce="option => option.value" :clearable="false"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <!-- <label class="ml-auto vs-input--label">O CFC</label>
          <v-select @input="changeTypeSingleReceipt" class="ml-auto" v-model="singleReceipt.type_single_receipt" :reduce="option => option.value" :clearable="false"
            :options="[
                {value: 'pay', label: 'Paga'},
                {value: 'receive', label: 'Recebe'},
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select> -->
        </div>
      </div>

      <div class="flex items-end mt-2">
        <!-- <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" /> -->
        <span class="leading-none font-medium">Recebedor</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full" label="Para maior clareza firmo o presente" v-model="singleReceipt.receiver_name" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full" label="CPF/CNPJ" v-model="singleReceipt.receiver_cpf_cnpj" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-as="CPF" data-vv-scope="sigleReceiptForm" v-validate="'cpf'" name="cpf" />
          <span class="text-danger text-sm" v-show="errors.has('sigleReceiptForm.cpf')">O documento não é válido</span>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Gerar Recibo</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import extenso from 'extenso'
import axios from '@/axios.js'
import { getValue, setValue, CurrencyDirective } from 'vue-currency-input'

import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleSingleReceipt from '@/store/single-receipt/moduleSingleReceipt.js'
import moduleStates from '@/store/state/moduleStates.js'
// import SelectStudentOrInputText from '@/components/register/student/SelectStudentOrInputText'
import SelectStudent from '@/components/register/student/SelectStudent.vue'
import SigButonInfo from '@/components/sig/SigButtonInfo'

export default {
  components: {
    vSelect,
    SelectStudent,
    SigButonInfo
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    label: {
    },
    value: {}    
  },

  computed: {
    validateSigleReceiptForm () {
      return !this.errors.any('sigleReceiptForm')
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    states () {
      return this.$store.getters['states/getStates']
    },
    validateForm () {
      return !this.errors.any()
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    user () {
      return this.$store.state.AppActiveUser
    }
  },

  data () {
    return {
      localShow: false,
      cityOptions:[],
      student: null,
      nameStudent: null,
      showSelectStudent: false,
      classAccount: 'text-primary cursor-pointer',
      classCashier: 'text-secondary cursor-pointer',     
      type_person_label: 'aluno',
      singleReceipt: {
        type_person: 'student',
        type_single_receipt: 'receive',
        value: 0,
        valueExtenso: '',
        payer_name: null, // aquele que paga o valor
        payer_cpf_cnpj: null, // aquele que paga o valor
        payer_street: null,
        payer_number: null, // address number
        payer_complement: null,
        payer_neighborhood: null,
        payer_zip_code: null,
        payer_city_id: null,
        payer_state_id: null,
        receiver_name: null, // aquele que recebe o valor
        receiver_cpf_cnpj: null, // aquele que recebe o valor
        data: this.today,
        referente: null,
        type_payment_id: null,
        payer_id: null,
        receiver_id: null,
        transaction_id: null
      },
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      }
    }
  },
  directives: { currency: CurrencyDirective },

  watch: {
    show (val) {
      this.localShow = val
      this.singleReceipt = {
        type_person: 'student',
        type_single_receipt: 'receive',
        value: 0,
        valueExtenso: '',
        payer_name: null, // aquele que paga o valor
        payer_cpf_cnpj: null, // aquele que paga o valor
        payer_street: null,
        payer_number: null, // address number
        payer_complement: null,
        payer_neighborhood: null,
        payer_zip_code: null,
        payer_city_id: null,
        payer_state_id: null,
        receiver_name: this.user.company.corporate_name || null, // aquele que recebe o valor
        receiver_cpf_cnpj: this.user.company.cnpj || null, // aquele que recebe o valor
        data: this.today,
        referente: null,
        type_payment_id: null,
        payer_id: null,
        receiver_id: null,
        transaction_id: null
      }
      setValue(this.$refs.singleReceiptValue, '0')
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
      }
    }
  },

  methods: {
    changePlaceHolder () {
      if (this.singleReceipt.type_person === 'student') {
        this.type_person_label = 'aluno'
      } else if (this.singleReceipt.type_person === 'provider') {
        this.type_person_label = 'fornecedor'
      }
    },
    changeSelect () {
      this.showSelectStudent = !this.showSelectStudent
      const temp = this.classAccount
      this.classAccount = this.classCashier
      this.classCashier = temp
    },
    changeValue () {
      if (this.showSelectStudent) {
        this.afterSelectStudent(this.student);
        this.$emit('input', this.student)
      } else {
        this.$emit('input', this.nameStudent)
      }
    },    
    action () {
      this.$emit('action')
    },
    afterSelectStudent (student) {
      if (typeof student === 'string') {
        this.singleReceipt.payer_name = student
        this.singleReceipt.payer_cpf_cnpj = ''
      } else {
        this.singleReceipt.payer_name = student.name
        this.singleReceipt.payer_cpf_cnpj = student.cpf
        axios.get(`${process.env.VUE_APP_API_URL}/student/${student.id}`)
          .then(resp => {
            if (!resp.data.address) return

            this.singleReceipt.payer_street = resp.data.address.street
            this.singleReceipt.payer_number = resp.data.address.number
            this.singleReceipt.payer_complement = resp.data.address.complement
            this.singleReceipt.payer_neighborhood = resp.data.address.neighborhood
            this.singleReceipt.payer_zip_code = resp.data.address.zip_code
            this.singleReceipt.payer_state_id = resp.data.address.state_id
            this.fillCity(this.singleReceipt.payer_state_id, resp.data.address.city_id)

            if (this.singleReceipt.type_single_receipt === 'pay') {
              this.singleReceipt.receiver_name = resp.data.name
              this.singleReceipt.receiver_cpf_cnpj = resp.data.cpf
            }
          })
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      try {
        this.$vs.loading()

        if (this.$refs.singleReceiptValue) this.singleReceipt.value = getValue(this.$refs.singleReceiptValue)
        else this.singleReceipt.value = 0

        const resp = await this.$store.dispatch('singleReceipt/store', this.singleReceipt)
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
        this.$emit('print', {data:{id:resp.data.id}})
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    changeCurrency () {
      this.singleReceipt.valueExtenso = extenso(this.$refs.singleReceiptValue.value.toString().slice(3) || 0, { mode: 'currency' })
    },
    async changeTypeSingleReceipt () {
      if (this.singleReceipt.type_single_receipt === 'pay') {
        this.singleReceipt.receiver_name = null
        this.singleReceipt.receiver_cpf_cnpj = null

        // this.singleReceipt.payer_name = this.user.company.corporate_name || null
        // this.singleReceipt.payer_cpf_cnpj = this.user.company.cnpj || null

        // this.singleReceipt.payer_street = this.user.company.addresses.street || null
        // this.singleReceipt.payer_number = this.user.company.addresses.number || null
        // this.singleReceipt.payer_complement = this.user.company.addresses.complement || null
        // this.singleReceipt.payer_neighborhood = this.user.company.addresses.neighborhood || null
        // this.singleReceipt.payer_zip_code = this.user.company.addresses.zip_code || null
        // this.singleReceipt.payer_state_id = this.user.company.addresses.state_id || null
        // if (this.singleReceipt.payer_state_id) {
        //   await this.fillCity(this.singleReceipt.payer_state_id, this.user.company.addresses.city_id)
        // }

      } else {
        this.singleReceipt.receiver_name = this.user.company.corporate_name || null
        this.singleReceipt.receiver_cpf_cnpj = this.user.company.cnpj || null

        this.singleReceipt.payer_name = null
        this.singleReceipt.payer_cpf_cnpj = null
        this.singleReceipt.payer_street = null
        this.singleReceipt.payer_number = null
        this.singleReceipt.payer_complement = null
        this.singleReceipt.payer_neighborhood = null
        this.singleReceipt.payer_zip_code = null
        this.singleReceipt.payer_city_id = null
        this.singleReceipt.payer_state_id = null
      }
    },
    async searchZipCode ($event) {
      const zip_code = this.singleReceipt.payer_zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        axios._noHeaders = true //enviando um get sem Autorization no header
        const resp = await axios.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position: 'center-top'
          })
          return
        }

        this.singleReceipt.payer_street = resp.data.logradouro
        this.singleReceipt.payer_neighborhood = resp.data.bairro
        this.singleReceipt.payer_complement = resp.data.complemento

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.singleReceipt.payer_state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },
    async fillCity (stateId, city = null, prop = 'cityOptions', prop2 = 'main') {
      this[prop] = []
      try {
        if (stateId) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          for (const k in resp.data) {
            this[prop].push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this[prop].find(x => x.value === city)
            if (!ct) {
              ct = this[prop].find(x => x.label === city)
            }
            if (prop2 === 'main') {
              this.singleReceipt.payer_city_id = ct.value
            }
          }
        }
      } catch (error) { console.error(error) }
    }
  },
  created () {
    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleSingleReceipt.isRegistered) {
      this.$store.registerModule('singleReceipt', moduleSingleReceipt)
      moduleSingleReceipt.isRegistered = true
    }
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_160 .vs__dropdown-menu {
    max-height: 160px;
  }
</style>
