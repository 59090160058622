var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-0" }, [
              _c("h3", [_vm._v("RECIBO")]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-3" },
              [
                _c("label", { staticClass: "ml-auto vs-input--label" }, [
                  _vm._v("O CFC"),
                ]),
                _c("v-select", {
                  staticClass: "ml-auto",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: "pay", label: "Paga" },
                      { value: "receive", label: "Recebe" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.changeTypeSingleReceipt },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.singleReceipt.type_single_receipt,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "type_single_receipt", $$v)
                    },
                    expression: "singleReceipt.type_single_receipt",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-0" }, [
              _c("div", [
                _c("label", { staticClass: "text-sm" }, [
                  _vm._v("Tipo de Recibo"),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "vs-radio",
                      {
                        staticClass: "mr-4",
                        attrs: { "vs-value": "student" },
                        on: {
                          change: function ($event) {
                            return _vm.changePlaceHolder()
                          },
                        },
                        model: {
                          value: _vm.singleReceipt.type_person,
                          callback: function ($$v) {
                            _vm.$set(_vm.singleReceipt, "type_person", $$v)
                          },
                          expression: "singleReceipt.type_person",
                        },
                      },
                      [_vm._v("Aluno")]
                    ),
                    _c(
                      "vs-radio",
                      {
                        staticClass: "mr-4",
                        attrs: { "vs-value": "provider" },
                        on: {
                          change: function ($event) {
                            return _vm.changePlaceHolder()
                          },
                        },
                        model: {
                          value: _vm.singleReceipt.type_person,
                          callback: function ($$v) {
                            _vm.$set(_vm.singleReceipt, "type_person", $$v)
                          },
                          expression: "singleReceipt.type_person",
                        },
                      },
                      [_vm._v("Fornecedor")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "singleReceiptValue",
                  staticClass: "w-full",
                  attrs: { label: "" },
                  on: { input: _vm.changeCurrency },
                  model: {
                    value: _vm.singleReceipt.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "value", $$v)
                    },
                    expression: "singleReceipt.value",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mt-0" }, [
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "vs-input--label" },
                    [
                      _c(
                        "sig-button-info",
                        {
                          attrs: {
                            title: `Nome ou ${_vm.type_person_label}`,
                            text: `Permite digitar o nome avulso ou selecionar o aluno. Clique na opção desejada - Nome -  ${_vm.type_person_label}`,
                          },
                        },
                        [
                          _c("div", { staticClass: "inline-flex" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.label) +
                                "\n                "
                            ),
                            _vm.label
                              ? _c("span", [
                                  _vm._v(
                                    "\n                   \n                "
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: _vm.classAccount,
                                on: { click: _vm.changeSelect },
                              },
                              [_vm._v("Digitar nome")]
                            ),
                            _vm._v("\n                 - \n                "),
                            _c(
                              "span",
                              {
                                class: _vm.classCashier,
                                on: { click: _vm.changeSelect },
                              },
                              [
                                _vm._v(
                                  "Selecionar " + _vm._s(_vm.type_person_label)
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.showSelectStudent
                    ? _c("select-student", {
                        attrs: {
                          label: "",
                          type_person: _vm.singleReceipt.type_person,
                        },
                        on: { input: _vm.changeValue },
                        model: {
                          value: _vm.student,
                          callback: function ($$v) {
                            _vm.student = $$v
                          },
                          expression: "student",
                        },
                      })
                    : _c("vs-input", {
                        staticClass: "w-full",
                        on: { input: _vm.changeValue },
                        model: {
                          value: _vm.nameStudent,
                          callback: function ($$v) {
                            _vm.nameStudent = $$v
                          },
                          expression: "nameStudent",
                        },
                      }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["###.###.###-##", "##.###.###/####-##"],
                      expression: "['###.###.###-##','##.###.###/####-##']",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "cpf",
                      expression: "'cpf'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: "CPF/CNPJ",
                    "data-vv-as": "CPF",
                    "data-vv-scope": "sigleReceiptForm",
                    name: "cpf",
                  },
                  model: {
                    value: _vm.singleReceipt.payer_cpf_cnpj,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_cpf_cnpj", $$v)
                    },
                    expression: "singleReceipt.payer_cpf_cnpj",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("sigleReceiptForm.cpf"),
                        expression: "errors.has('sigleReceiptForm.cpf')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("O documento não é válido")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Endereço"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["#####-###"],
                      expression: "['#####-###']",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { label: "CEP", placeholder: "CEP" },
                  on: { keyup: _vm.searchZipCode },
                  model: {
                    value: _vm.singleReceipt.payer_zip_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_zip_code", $$v)
                    },
                    expression: "singleReceipt.payer_zip_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Endereço" },
                  model: {
                    value: _vm.singleReceipt.payer_street,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_street", $$v)
                    },
                    expression: "singleReceipt.payer_street",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    label: "Número",
                    type: "number",
                    placeholder: "S/N",
                  },
                  model: {
                    value: _vm.singleReceipt.payer_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_number", $$v)
                    },
                    expression: "singleReceipt.payer_number",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Complemento" },
                  model: {
                    value: _vm.singleReceipt.payer_complement,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_complement", $$v)
                    },
                    expression: "singleReceipt.payer_complement",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Bairro" },
                  model: {
                    value: _vm.singleReceipt.payer_neighborhood,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_neighborhood", $$v)
                    },
                    expression: "singleReceipt.payer_neighborhood",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cidade"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_240",
                  attrs: {
                    reduce: (option) => option.value,
                    placeholder: _vm.cityOptions.length
                      ? "Selecione"
                      : "Selecione um estado",
                    options: _vm.cityOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.singleReceipt.payer_city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_city_id", $$v)
                    },
                    expression: "singleReceipt.payer_city_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_240",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      _vm.fillCity(_vm.singleReceipt.payer_state_id, null),
                        (_vm.singleReceipt.payer_city_id = null)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.singleReceipt.payer_state_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "payer_state_id", $$v)
                    },
                    expression: "singleReceipt.payer_state_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-4 mb-4" }, [
              _c("strong", [
                _vm._v(
                  "A importância de " + _vm._s(_vm.singleReceipt.valueExtenso)
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0 mb-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Pago em",
                    "data-vv-scope": "sigleReceiptForm",
                    "data-vv-as": "Pago em",
                    name: "data",
                  },
                  model: {
                    value: _vm.singleReceipt.data,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "data", $$v)
                    },
                    expression: "singleReceipt.data",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("sigleReceiptForm.data"),
                        expression: "errors.has('sigleReceiptForm.data')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("sigleReceiptForm.data")))]
                ),
              ],
              1
            ),
          ]),
          _c("hr", { staticClass: "mt-2" }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-3/4 w-full mt-4 mb-4" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Referente:"),
                ]),
                _c("vs-textarea", {
                  attrs: { rows: "5", width: "100%", placeholder: "..." },
                  model: {
                    value: _vm.singleReceipt.referente,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "referente", $$v)
                    },
                    expression: "singleReceipt.referente",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-4 mb-4" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipos de pagamento da conta"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.typePayments,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.singleReceipt.type_payment_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "type_payment_id", $$v)
                    },
                    expression: "singleReceipt.type_payment_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex items-end mt-2" }, [
            _c("span", { staticClass: "leading-none font-medium" }, [
              _vm._v("Recebedor"),
            ]),
          ]),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Para maior clareza firmo o presente" },
                  model: {
                    value: _vm.singleReceipt.receiver_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "receiver_name", $$v)
                    },
                    expression: "singleReceipt.receiver_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["###.###.###-##", "##.###.###/####-##"],
                      expression: "['###.###.###-##','##.###.###/####-##']",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "cpf",
                      expression: "'cpf'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: "CPF/CNPJ",
                    "data-vv-as": "CPF",
                    "data-vv-scope": "sigleReceiptForm",
                    name: "cpf",
                  },
                  model: {
                    value: _vm.singleReceipt.receiver_cpf_cnpj,
                    callback: function ($$v) {
                      _vm.$set(_vm.singleReceipt, "receiver_cpf_cnpj", $$v)
                    },
                    expression: "singleReceipt.receiver_cpf_cnpj",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("sigleReceiptForm.cpf"),
                        expression: "errors.has('sigleReceiptForm.cpf')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("O documento não é válido")]
                ),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Gerar Recibo")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }